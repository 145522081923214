import "core-js/modules/es.array.push.js";
import { activeWeek, activeDay, getWeeks, getStatus, provinceFind, cityFind, areaFind, photos } from '@/api/wxproject';
import Selector from '@/components/Selector';
import dayjs from 'dayjs';
export default {
  components: {
    Selector
  },
  data() {
    return {
      type: '',
      searchname: '',
      activeName: '周',
      statusList: [],
      listDataWeek: [],
      listData: [],
      weeklist: [],
      weeklistdata: [],
      showPicker: false,
      status: '',
      result: '',
      result1: '',
      result1data: '',
      listDataDay: [],
      Daylist: [],
      minDate: '',
      maxDate: '',
      provinceList: [],
      cityList: [],
      areaList: [],
      province: '',
      city: '',
      area: ''
    };
  },
  mounted() {
    document.title = '活跃详情';
    this.type = this.$route.query.type;
    this.activeName = this.$route.query.dateType || '周';
    // this.province = '330000'
    // this.province = '330000'
    // this.city = '330300'
    // this.area = '330302'
    this.getProvince();
    console.log(this.$route.query);
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
      this.status = this.$route.query.status;
      this.searchname = this.$route.query.searchname;
      this.province = this.$route.query.province;
      this.city = this.$route.query.city;
      this.area = this.$route.query.area;
      this.getPCA();
    }
    this.getData();
    if (this.$route.query.type === 'now') {
      this.getStatus();
    } else {
      this.statusList = [{
        value: '',
        label: '不限'
      }, {
        value: 'att_pass',
        label: '活跃合格'
      }, {
        value: 'att_fail',
        label: '活跃异常'
      }, {
        value: 'no_att',
        label: '未活跃'
      }];
    }
  },
  computed: {
    filterStatusList() {
      if (this.listDataDay.projectCategory === 'LHDG') {
        return this.statusList.filter(item => item.value !== 'no_att');
      } else {
        return this.statusList;
      }
    }
  },
  methods: {
    goback() {
      this.$router.push({
        path: '/RealtimedynamicDetails',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type: this.$route.query.type
        }
      });
      // window.history.back()
    },

    godate(date) {
      this.activeName = '日';
      console.log(date.slice(0, 10));
      this.result1 = date.slice(0, 10);
      this.onConfirm1(date.slice(0, 10));
    },
    getData() {
      let query = this.$route.query;
      activeWeek({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        type: this.$route.query.type
        // weekNo:3,
      }).then(res => {
        // if(res.data.data.dayAtt){
        //   let list = []
        //   list = res.data.data.dayAtt.map(res1=>{
        //     console.log(res1.date)
        //     res1.date = res1.date.slice(5)
        //     return res1
        //   })
        //   res.data.data.dayAtt = list
        // }

        this.listDataWeek = res.data.data;
        getWeeks({
          // month:this.$route.query.month,
          projectCode: this.$route.query.projectCode,
          type: this.$route.query.type
        }).then(res3 => {
          this.weeklistdata = res3.data.data;
          let list = [];
          res3.data.data.map(res4 => {
            list.push(res4.value);
          });
          this.weeklist = list;
          console.log(list);
          this.result = list[res.data.data.weekNo - 1];
        });
      });
      activeDay({
        // projectCode:'WIKBCGX2202016000',
        // month:'2022-02',
        // type:'now',
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        type: this.$route.query.type,
        date: query.activeName && query.date,
        status: query.activeName && query.status,
        terminalName: query.activeName && query.searchname,
        province: query.activeName && query.province,
        city: query.activeName && query.city,
        area: query.activeName && query.area
      }).then(res5 => {
        console.log('222', query);
        this.listDataDay = res5.data.data;
        this.listData = res5.data.data.pgAttList;
        if (this.$route.query.type !== 'now') {
          this.result1 = res5.data.data.date;
          if (query.activeName) {
            this.result1 = query.date;
            console.log(this.result1);
          }
        }
        this.getdaylist();
      });
    },
    getData1() {
      activeDay({
        // projectCode:'WIKBCGX2202016000',
        // month:'2022-02',
        // type:'now',
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        type: this.$route.query.type,
        status: this.status,
        province: this.province,
        city: this.city,
        area: this.area,
        date: dayjs(this.result1).format('YYYY-MM-DD'),
        terminalName: this.searchname
      }).then(res5 => {
        this.listDataDay = res5.data.data;
        this.listData = res5.data.data.pgAttList;
        console.log(res5);
      });
    },
    onConfirm(value) {
      this.result = value;
      let weekNo = 0;
      this.weeklistdata.map(res6 => {
        if (res6.value === value) {
          weekNo = res6.code;
        }
      });
      activeWeek({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        type: this.$route.query.type,
        weekNo: weekNo
      }).then(res => {
        // if(res.data.data.dayAtt){
        //   let list = []
        //   list = res.data.data.dayAtt.map(res1=>{
        //     console.log(res1.date)
        //     res1.date = res1.date.slice(5)
        //     return res1
        //   })
        //   res.data.data.dayAtt = list
        // }

        this.listDataWeek = res.data.data;
        this.showPicker = false;
      });
    },
    onchange1(value) {
      console.log('value: ', value);
      // this.result1 = dayjs(value).format('YYYY-MM-DD');
    },

    onConfirm1(value) {
      this.status = '', this.province = '', this.city = '', this.area = '', this.searchname = '', this.result1 = dayjs(value).format('YYYY-MM-DD');
      activeDay({
        // projectCode:'WIKBCGX2202016000',
        // month:'2022-02',
        // type:'now',
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        type: this.$route.query.type,
        date: dayjs(value).format('YYYY-MM-DD')
      }).then(res5 => {
        this.listDataDay = res5.data.data;
        this.listData = res5.data.data.pgAttList;
        this.showPicker = false;
      });
    },
    getdaylist() {
      if (this.$route.query.type === 'now') {
        this.result1 = dayjs(new Date()).format('YYYY-MM-DD');
        if (this.$route.query.activeName) {
          this.result1 = this.$route.query.date;
        }
        // let date = new Date(), y = date.getFullYear(), m = date.getMonth()

        this.minDate = new Date(this.listDataDay.startDate.split('-').join('/'));
        this.maxDate = new Date(this.listDataDay.endDate.split('-').join('/'));

        // this.minDate = new Date(y, m, 1)
        // this.maxDate = new Date()
      } else {
        console.log(this.result1);
        // this.minDate = new Date(Number(this.listDataDay.startDate.slice(0,4)), Number(this.listDataDay.startDate.slice(5,7))-1, Number(this.listDataDay.startDate.slice(8,10)))
        // this.maxDate = new Date(Number(this.listDataDay.endDate.slice(0,4)), Number(this.listDataDay.endDate.slice(5,7))-1, Number(this.listDataDay.endDate.slice(8,10)))

        this.minDate = new Date(this.listDataDay.startDate.split('-').join('/'));
        this.maxDate = new Date(this.listDataDay.endDate.split('-').join('/'));
      }
    },
    getStatus() {
      getStatus().then(res8 => {
        this.statusList = res8.data.data.map(item => {
          return {
            label: item.value,
            value: item.code
          };
        });
        this.statusList.unshift({
          label: '不限',
          value: ''
        });
      });
    },
    getPCA() {
      cityFind({
        provinceCode: this.province
      }).then(res => {
        this.cityList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.cityList.unshift({
          label: '不限',
          value: ''
        });
        areaFind({
          cityCode: this.city
        }).then(res => {
          this.areaList = res.data.data.map(item => {
            return {
              label: item.name,
              value: item.code
            };
          });
          this.areaList.unshift({
            label: '不限',
            value: ''
          });
        });
      });
    },
    getProvince() {
      // this.provinceList.unshift({ label: '省22', value: '330000' });
      // this.provinceList.unshift({ label: '省221', value: '3300001' });
      // this.areaList.unshift({ label: '区22', value: '330302' });
      // this.areaList.unshift({ label: '区223', value: '3303023' });
      // this.cityList.unshift({ label: '市22', value: '330300' });
      // this.cityList.unshift({ label: '市2212', value: '33030011' });
      provinceFind().then(res => {
        this.provinceList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.provinceList.unshift({
          label: '不限',
          value: ''
        });
      });
    },
    getCity() {
      console.log(this.province);
      // this.cityList = [];
      this.areaList = [];
      this.areaList.unshift({
        label: '不限',
        value: ''
      });
      this.area = '';
      cityFind({
        provinceCode: this.province
      }).then(res => {
        this.cityList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.cityList.unshift({
          label: '不限',
          value: ''
        });
        this.city = '';
        this.getData1();
      });
    },
    getArea() {
      // this.areaList = [];
      areaFind({
        cityCode: this.city
      }).then(res => {
        this.areaList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.areaList.unshift({
          label: '不限',
          value: ''
        });
        this.area = '';
        this.getData1();
      });
    },
    implementationDetail(item) {
      console.log('item: ', item);
      const {
        pgCode: userCode,
        terminalCode: orgTerminalCode,
        planCode,
        batchCode,
        showSubmit
      } = item;
      this.$router.push({
        path: showSubmit ? '/ImplementationDetail2' : '/ImplementationDetail',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type1: this.$route.query.type1,
          type: this.$route.query.type,
          orgTerminalCode: orgTerminalCode,
          userCode: userCode,
          planCode: planCode,
          date: this.result1,
          activeName: this.activeName,
          status: this.status,
          searchname: this.searchname,
          province: this.province,
          city: this.city,
          area: this.area,
          batchCode: batchCode
        }
      });
    },
    updatesearch() {
      this.getData1();
    },
    getPhotos(data) {
      if (data.count > 2) return;
      photos({
        date: this.result1,
        pgAttList: [data]
      }).then(res => {
        var _res$data$data$;
        data.url = (_res$data$data$ = res.data.data[0]) === null || _res$data$data$ === void 0 ? void 0 : _res$data$data$.startPhotoUrl;
        if (data.count) {
          data.count += 1;
        } else {
          data.count = 1;
        }
      });
    },
    goActiveStatistics() {
      console.log('this.$route.query.projectCode: ', this.$route.query.projectCode);
      this.$router.push({
        path: '/activeStatistics',
        query: {
          projectCode: this.$route.query.projectCode,
          date: this.result1,
          minDate: this.listDataDay.startDate,
          maxDate: this.listDataDay.endDate
        }
      });
    }
  }
};